import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import ReactMarkdown from "react-markdown";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "How do I participate?",
      answer: `Connect Camp Apex to any Trailhead or Dev Org using the 'Login' button. Navigate to the Weekly Challenges section, and start solving! Each challenge includes detailed instructions, test cases, and a code editor where you can write and submit your solutions. Your progress is automatically tracked on the leaderboard.`,
    },
    {
      question: "When do challenges drop?",
      answer: `Daily at 9:00 AM EST (14:00 UTC). `,
    },
    {
      question: "How are points calculated?",
      answer: `There are two ways to earn points:\n\n1) Challenge completion - you earn points when all test scenarios pass for a challenge\n\n2) Streak bonus - you receive bonus points for returning multiple days in a row to solve challenges.\n\nBoth challenge completion points and streak bonuses contribute to your position on the leaderboard.`,
    },
    {
      question: "Can I modify my solution after submitting?",
      answer:
        "Yes! You can modify and resubmit your solutions at any time. The system will keep track of your latest submission.",
    },
    {
      question: "What happens if I'm stuck on a challenge?",
      answer:
        "If you're stuck, review the test cases carefully and try different approaches. If you're still stuck, join us on Slack.",
    },
    {
      question: "I think I found a bug",
      answer:
        "If you think you found one, let me know in Slack or [here](https://tally.so/r/mDzZVj)",
    },
    {
      question: "Is there a Slack group?",
      answer:
        "Yes! Join us at [Camp Apex Slack](https://join.slack.com/t/campapexorg/shared_invite/zt-2x349b383-zEistSk09EI79TBm8TIkjw) where you can ask questions, get help, and help others.",
    },
    {
      question: "How does the charitable donation work?",
      answer: "The money you donate goes directly to the charity via GoFundMe.",
    },
    {
      question: "Why did you build all this?",
      answer: `I really like Salesforce, I really like coding, and I wanted to help make some people smile.`,
    },
  ];

  return (
    <div className="prose prose-invert max-w-none">
      {faqs.map((faq, index) => (
        <div key={index} className="bg-zinc-800 rounded-lg mb-4">
          <button
            className="w-full p-4 text-left flex items-center justify-between bg-zinc-800 hover:bg-zinc-700 rounded-lg transition-colors duration-300"
            onClick={() => setOpenIndex(openIndex === index ? null : index)}
          >
            <span className="text-slate-100">{faq.question}</span>
            <FiChevronDown
              className={`text-slate-100 transform transition-transform duration-300 ${
                openIndex === index ? "rotate-180" : ""
              }`}
            />
          </button>
          <div
            className={`overflow-hidden transition-all duration-300 ${
              openIndex === index ? "max-h-96" : "max-h-0"
            }`}
          >
            <div className="p-4 text-slate-100">
              <ReactMarkdown>{faq.answer}</ReactMarkdown>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
