import { Link, useLocation } from "react-router-dom";
import {
  FaCampground,
  FaSlack,
  FaHandHoldingMedical,
  FaComment,
  FaInfoCircle,
  FaLinkedin,
} from "react-icons/fa";
import { useState, useEffect } from "react";
import { GiBearHead } from "react-icons/gi";

function Footer({ path }) {
  const { pathname } = useLocation();

  const [isDesktop, setDesktop] = useState(window.innerWidth > 1050);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1050);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  if (
    pathname.includes("lesson") ||
    pathname.includes("practice/flow") ||
    pathname.includes("advent/")
  )
    return <></>;

  return (
    <footer className="footer bg-black text-black-content text-white pb-3 pt-4 ">
      {!isDesktop ? (
        <>
          <div className="flex flex-col items-center justify-center space-y-2 w-full">
            {/* First row of links */}
            <div className="flex items-center space-x-5 text-base-300">
              <GiBearHead color="gold" className="mr-2" /> Camp Apex
            </div>

            <div className="flex items-center space-x-2">
              <Link to="/about" className="text-xs">
                About
              </Link>
              <span>|</span>
              <a
                className="text-xs"
                href="https://tally.so/r/mDzZVj"
                target="_blank"
                rel="noopener noreferrer" // Added for security reasons
              >
                Feedback & Bugs
              </a>
              <span>|</span>
              <a
                className="text-xs"
                href="https://www.buymeacoffee.com/CampApexDotOrg"
                target="_blank"
                rel="noopener noreferrer" // Added for security reasons
              >
                Support
              </a>
            </div>

            {/* Second row of links */}
            <div className="flex items-center space-x-2">
              <a
                className="text-xs"
                href="https://join.slack.com/t/campapexorg/shared_invite/zt-2x349b383-zEistSk09EI79TBm8TIkjw"
                target="_blank"
                rel="noopener noreferrer" // Added for security reasons
              >
                Slack
              </a>
              <a
                className="text-xs"
                href="https://www.linkedin.com/in/saman-attar/"
                target="_blank"
                rel="noopener noreferrer" // Added for security reasons
              >
                LinkedIn
              </a>

              <span>|</span>
              <Link to="/privacy" className="text-xs">
                Privacy Policy
              </Link>
              <span>|</span>
              <Link to="/cookie" className="text-xs">
                Cookie Policy
              </Link>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <div className="flex-none px-3 mx-2 mt-3">
              <FaCampground className="inline pr-2 text-3xl" />
              <Link to="/" className="text-lg font-bold align-middle ">
                Camp Apex
              </Link>
            </div>
          </div>
          <div>
            <span className="footer-title">About</span>
            <Link to="/about" className="text-md  align-middle">
              <FaInfoCircle className="inline pr-2 text-2xl" />
              About
            </Link>
          </div>
          <div>
            <span className="footer-title">Reach out</span>
            <a
              className="text-md  align-middle"
              href="https://tally.so/r/mDzZVj"
              target="_blank"
            >
              <FaComment className="inline pr-2 text-2xl" />
              Give Feedback & Report Bugs
            </a>
            <a
              className="text-md  align-middle"
              href="https://www.buymeacoffee.com/CampApexDotOrg"
              target="_blank"
            >
              <FaHandHoldingMedical className="inline pr-2 text-2xl" />
              Support
            </a>
          </div>
          <div>
            <span className="footer-title">Connect</span>
            <a
              className="text-md  align-middle"
              href="https://join.slack.com/t/campapexorg/shared_invite/zt-2x349b383-zEistSk09EI79TBm8TIkjw"
              target="_blank"
            >
              <FaSlack className="inline pr-2 text-2xl" />
              Slack
            </a>
            <a
              className="text-md  align-middle"
              href="https://www.linkedin.com/in/saman-attar/"
              target="_blank"
            >
              <FaLinkedin className="inline pr-2 text-2xl" />
              LinkedIn
            </a>
          </div>

          <div>
            <span className="footer-title">Legal</span>
            <Link to="/privacy" className="text-md  align-middle">
              Privacy Policy
            </Link>
            <Link to="/cookie" className="text-md  align-middle">
              Cookie Policy
            </Link>
          </div>
        </>
      )}
    </footer>
  );
}

export default Footer;
