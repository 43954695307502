import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEvents } from "../features/courses/courseSlice";
import { FaSlack } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const Event = () => {
  const { events } = useSelector((state) => state.courses);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);

  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const currentDate = new Date();
  const upcomingEvents = events?.filter(
    (event) => new Date(event.date) >= currentDate
  );
  const pastEvents = events?.filter(
    (event) => new Date(event.date) < currentDate
  );

  const handleRowClick = (event) => {
    setSelectedEventId(event._id);
    setSelectedEvent(event);
  };

  const renderTable = (eventsToRender, title, subtitle) => (
    <>
      <div className="col-span-3 flex justify-center items-center pt-4 pb-5">
        <h1 className="text-3xl">{title}</h1>
      </div>
      {subtitle && { subtitle }}
      <div className="md:col-span-2">
        <div className="overflow-x-auto">
          <table className="table w-full">
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody className="">
              {eventsToRender?.map((event) => (
                <tr
                  key={event._id}
                  className={`dov ${
                    selectedEventId === event._id
                      ? "text-purple-300"
                      : "text-white"
                  } cursor-pointer`}
                  onClick={() => handleRowClick(event)}
                >
                  <td className="bg-zinc-800">{event.name}</td>
                  <td className="bg-zinc-800">
                    {new Date(event.date).toLocaleString()}
                  </td>
                  <td className="bg-zinc-800">{event.location}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );

  return (
    <div className="grid grid-cols-1 xl:grid-cols-3 md:grid-cols-3 gap-4  xl:px-48 pt-16 px-2">
      <div className="col-span-3 flex justify-center items-center pt-4 pb-5">
        <h1 className="text-md">
          Build Nights are an opportunity to meet other Trailblazers and build
          apps together. Sometimes we build independently other times we build
          together. Whatever we do, it's not that serious. Come with a good
          attitude and we'll figure out the rest.{" "}
          <a
            className="text-md  align-middle"
            href="https://join.slack.com/t/campapexorg/shared_invite/zt-2x349b383-zEistSk09EI79TBm8TIkjw"
            target="_blank"
          >
            <div className="flex inline hover:text-[#C79AF5] pt-4">
              <FaSlack className=" pr-2 text-3xl text-blue-300 hover:text-[#C79AF5]" />{" "}
              Join the Slack for more info.
            </div>
          </a>
        </h1>
      </div>
      <div className="col-span-3 flex justify-center items-center pt-4 pb-5">
        <h1 className="text-md">
          Want to use Camp Apex to host your own event? Just{" "}
          <a
            className="text-md  align-middle hover:underline text-purple-300"
            href="https://tally.so/r/mDzZVj"
            target="_blank"
          >
            let me know.
          </a>
        </h1>
      </div>

      <div className="col-span-2">
        {/* Upcoming Events */}
        {renderTable(upcomingEvents, "Upcoming Events", "")}
      </div>
      <div className="col-span-1 row-start-3 lg:row-start-1 xl:row-span-2 w-full  flex items-center justify-center  xl:ml-32 px-1">
        {/* Event Info */}
        {selectedEvent && (
          <div className="md:col-span-1 pb-7">
            <div className="card bg-base-100 shadow-xl p-4">
              <div className="card-body flex items-center">
                <h2 className="card-title ">{selectedEvent.name}</h2>
                <p>{new Date(selectedEvent.date).toLocaleString()}</p>
                <p>Location: {selectedEvent.location}</p>
                <p>
                  <ReactMarkdown
                    className="text-center "
                    children={selectedEvent.details}
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  />
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="col-span-2 ">
        {/* Past Events */}
        {pastEvents?.length > 0 && (
          <div className="col-span-3 pt-16 lg:pb-32 pb-9">
            {renderTable(pastEvents, "Past Events")}
          </div>
        )}
      </div>
    </div>
  );
};

export default Event;
